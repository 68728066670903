<template>
  <header class="header">
    <div class="header__body">
      <nav class="header__menu" :class="{ active: mobileMenuActive }">
        <ul>
          <li>
            <router-link to="/" :class="{ active: mobileMenuActive }" @click.native="closeMobileMenu">
              Home
            </router-link>
          </li>
          <li v-if="mobileDisplaySize">
            <router-link to="/rides-for-family-members" :class="{ active: mobileMenuActive }" @click.native="closeMobileMenu">
              For Family
            </router-link>
          </li>
          <li v-if="mobileDisplaySize">
            <router-link to="/rides-for-healthcare-providers" @click.native="closeMobileMenu">
              For Providers
            </router-link>
          </li>
          <div class="dropdown" v-if="!mobileDisplaySize">
            <a class="dropdown-text">Ride With Us</a>
            <img class="dropdown-icon" src="@/assets/images/icon/barrow.svg" alt="">
            <div class="dropdown-content">
              <li>
                <router-link to="/rides-for-family-members" @click.native="closeMobileMenu">
                  For Family
                </router-link>
              </li>
              <li>
                <router-link to="/rides-for-healthcare-providers" @click.native="closeMobileMenu">
                  For Providers
                </router-link>
              </li>
            </div>
          </div>
          <li>
            <router-link to="/join-our-fleet" @click.native="closeMobileMenu">
              Join Our Fleet
            </router-link>
          </li>
        </ul>
        <span v-if="mobileDisplaySize">
          <router-link to="/login" v-if="!user" @click.native="closeMobileMenu">
            <RoundedButton>
              <template #icon-left>
                <svg
                  width="17"
                  height="20"
                  viewBox="0 0 17 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 18.1577V17.2157C1 14.0942 3.5184 11.5637 6.625 11.5637H10.375C13.4816 11.5637 16 14.0942 16 17.2157V18.1577"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M8.5 8.73768C6.42893 8.73768 4.75 7.05069 4.75 4.96967C4.75 2.88865 6.42893 1.20166 8.5 1.20166C10.571 1.20166 12.25 2.88865 12.25 4.96967C12.25 7.05069 10.571 8.73768 8.5 8.73768Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </template>

              <template #default> Log in </template>
            </RoundedButton>
          </router-link>
          <router-link :to="{ name: 'Register' }" class="uk-text-bold" v-if="!user">
            <RoundedButton>
              <template #default> Sign Up </template>
            </RoundedButton>
          </router-link>
          <router-link
            to="/dashboard/view"
            v-if="user"
            @click.native="closeMobileMenu"
          >
            <RoundedButton>
              <template #default> Dashboard </template>
            </RoundedButton>
          </router-link>
          <ContactPopupDialog />
        </span>
      </nav>
      <div class="header__logo">
        <router-link :to="{ name: 'HomePage' }">
          <img src="@/assets/images/logo.png" alt="" />
        </router-link>
      </div>
      <div class="header__buttons">
        <span v-if="!mobileDisplaySize">
          <router-link to="/login" v-if="!user">
            <RoundedButton>
              <template #icon-left>
                <svg
                  width="17"
                  height="20"
                  viewBox="0 0 17 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 18.1577V17.2157C1 14.0942 3.5184 11.5637 6.625 11.5637H10.375C13.4816 11.5637 16 14.0942 16 17.2157V18.1577"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M8.5 8.73768C6.42893 8.73768 4.75 7.05069 4.75 4.96967C4.75 2.88865 6.42893 1.20166 8.5 1.20166C10.571 1.20166 12.25 2.88865 12.25 4.96967C12.25 7.05069 10.571 8.73768 8.5 8.73768Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </template>

              <template #default> Log in </template>
            </RoundedButton>
          </router-link>
          <router-link :to="{ name: 'Register' }" class="uk-text-bold" v-if="!user">
            <RoundedButton>
              <template #default> Sign Up </template>
            </RoundedButton>
          </router-link>
          <router-link to="/dashboard/view" v-if="user">
            <RoundedButton>
              <template #default> Dashboard </template>
            </RoundedButton>
          </router-link>
          <ContactPopupDialog />
        </span>
        <div class="header__burger" :class="{ active: mobileMenuActive }">
          <svg
            class="ham hamRotate ham8"
            :class="{ active: mobileMenuActive }"
            color="white"
            viewBox="0 0 100 100"
            @click="toggleMobileMenu"
          >
            <path
              class="line top"
              d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
            />
            <path class="line middle" d="m 30,50 h 40" />
            <path
              class="line bottom"
              d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
            />
          </svg>
        </div>
        <a class="header__phone-number" href="tel:+14106965200">410-696-5200</a>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from 'vuex';

import ContactPopupDialog from '../../../../src/components/ContactPopupDialog.vue';
import RoundedButton from '../../../../src/components/RoundedButton.vue';

export default {
  name: 'Header',
  components: {
    RoundedButton,
    ContactPopupDialog,
  },
  data() {
    return {
      mobileMenuActive: false,
      mobileDisplaySize: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'strapi', 'isAdmin']),
  },
  mounted() {
    this.checkWindowSize();

    this.$nextTick(() => {
      window.addEventListener('resize', this.checkWindowSize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowSize);
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuActive ? this.closeMobileMenu() : this.openMobileMenu();
    },
    openMobileMenu() {
      this.mobileMenuActive = true;
      this.lockBody();
    },
    closeMobileMenu() {
      this.mobileMenuActive = false;
      this.unlockBody();
    },
    lockBody() {
      document.querySelector('body').classList.add('lock');
    },
    unlockBody() {
      document.querySelector('body').classList.remove('lock');
    },
    checkWindowSize() {
      if (window.innerWidth > 940) {
        this.closeMobileMenu();
        this.mobileDisplaySize = false;
      } else {
        this.mobileDisplaySize = true;
      }
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  min-width: 150px;
  //position: absolute;
  //margin-left: 8%;
}

.dropdown-text {
  margin-right: 0 !important;
  padding-left: 10px;
}

.dropdown-icon {
  padding-top: 10px;
  height: 20px;
  width: 20px;
}

.dropdown:hover .dropdown-content {
  min-width: 150px;
  padding-left: 10px;
  position: absolute;
  display: block;
  background-color: rgb(62, 104, 138, .7);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
 }

.dropdown-content {
  display: none;
  z-index: 10;
}

.header {
  width: 100%;
  background: $primary-color;

  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);

  position: relative;
  z-index: 10;

  &__body {
    width: 100%;
    max-width: $containerMaxWidth;
    margin: 0px auto;

    padding: 0 25px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
  }

  &__logo {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    width: 100%;
    max-width: 200px;

    @media screen and (max-width: 1300px) {
      display: flex;

      order: 1;
      transform: none;

      position: relative;
      top: auto;
      left: auto;
    }

    @media screen and (max-width: 940px) {
      margin-right: 25px;
    }

    a {
      display: flex;

      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__menu {
    width: 100%;
    max-width: 430px;
    padding: 32px 0;

    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
    }

    a {
      color: $white;
      font-size: 18px;
      font-weight: 500;

      transition: all 0.2s linear;

      &:not(:last-child) {
        margin-right: 25px;
      }

      &.router-link-exact-active {
        color: $secondary-color;
        font-weight: 700;
      }
    }

    @media screen and (max-width: 1300px) {
      order: 2;
      padding: 32px 25px;
    }

    @media screen and (max-width: 940px) {
      padding: 0px;
      margin: 0px;

      position: fixed;
      top: -100%;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      overflow: auto;
      background: $primary-color;

      display: flex;
      justify-content: center;
      align-items: center;

      flex-direction: column;

      z-index: 1000;

      transition: all 0.3s ease 0s;

      &.active {
        transform: translateY(100%);
        margin-right: 17px;
      }

      ul {
        flex-direction: column;
        margin-bottom: 50px;

        text-align: center;

        li {
          &:not(:last-child) {
            margin-bottom: 30px;
          }

          a {
            font-size: 22px;
          }
        }
      }

      span {
        display: flex;
        align-items: center;

        .rounded-button:not(:last-child) {
          margin-right: 15px;
        }
      }
    }

    @media screen and (max-width: 530px) {
      padding: 20px 0;
    }
  }
  &__phone-number {
    margin-top: 20px;
    color: white !important;
    font-size: 20px;
    font-weight: bold;
  }
  
  &__buttons {
    padding: 32px 0;
    display: flex;
    flex-flow: column;
    align-items: center;

    order: 3;

    span {
      display: flex;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 15px;
      }
    }

    @media screen and (max-width: 940px) {
      padding: 10px 0;
    }
  }

  &__burger {
    width: 70px;
    overflow: hidden;

    opacity: 0;
    display: none;
    z-index: 1001;

    @media screen and (max-width: 940px) {
      display: block;
      opacity: 1;
      transition: all 0.3s linear;
    }
  }
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Burger styles
.hamRotate.active {
  transform: rotate(45deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #fff;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.ham8 .top {
  stroke-dasharray: 40 160;
}

.ham8 .middle {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}

.ham8 .bottom {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}

.ham8.active .top {
  stroke-dashoffset: -64px;
}

.ham8.active .middle {
  //stroke-dashoffset: -20px;
  transform: rotate(90deg);
}

.ham8.active .bottom {
  stroke-dashoffset: -64px;
}
</style>
