<template>
  <button
    :disabled="disabled"
    class="rounded-button"
    :class="{
      'primary-type': type == 'primary',
      'white-type': type == 'white',
      disabled: disabled,
    }"
  >
    <div>
      <div class="icon-left"><slot name="icon-left"></slot></div>
      <slot></slot>
      <div class="icon-right"><slot name="icon-right"></slot></div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      value: String,
      default: "primary",
    },
    disabled: {
      value: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.rounded-button {
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  font-size: 16px;
  font-weight: 500;

  border-radius: 30px;
  transition: all 0.2s linear;

  white-space: nowrap;

  &.primary-type {
    background: $primary-color;
    color: $white;

    padding: 4px 10px;

    &:hover {
      background: $white;
      color: $secondary-color;

      svg {
        path {
          stroke: $secondary-color;
        }
      }
    }
  }

  &.white-type {
    position: relative;

    & > div {
      border-radius: 30px;
      transition: all 0.2s linear;

      background: $white;
      color: $primary-color;

      padding: 6px 22px;

      border: 2px solid $white;

      position: relative;
      z-index: 2;

      &:hover {
        color: $secondary-color;
        border: 2px solid $secondary-color;

        svg {
          path {
            fill: $secondary-color;
          }
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      transform: translate(-50%, -50%);
      z-index: 1;

      background: $white;
      border-radius: 30px;
    }
  }

  &.disabled {
    background: #c8c8c8;

    &:hover {
      background: #c8c8c8;
    }

    & > div {
      color: $white;

      &:hover {
        color: $white;
        border: none;

        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }

  .icon-left {
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      margin-right: 7px;
    }
  }

  .icon-right {
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      margin-left: 20px;
    }
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      transition: all 0.2s linear;
    }
  }
}
</style>