<template>
  <div>
    <Header></Header>

    <v-content>
      <router-view />
    </v-content>

    <Footer />
  </div>
</template>

<script>
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { mapState, mapMutations } from "vuex";

export default {
  name: "Layout",

  components: {
    Header,
    Footer,
  },

  props: {
    source: String,
  },
  computed: {
    ...mapState({ Customizer_drawer: "app/Customizer_drawer" }),
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: "app/SET_CUSTOMIZER_DRAWER",
    }),
  },
};
</script>

<style scoped>
</style>
