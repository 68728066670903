<template>
  <div>
    <v-dialog v-model="dialog" max-width="890">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <RoundedButton :type="'white'"> Contact Us </RoundedButton>
        </div>
      </template>

      <ContactPopup @close="dialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import ContactPopup from "../components/ContactUs.vue";
import RoundedButton from "./RoundedButton.vue";

export default {
  components: {
    ContactPopup,
    RoundedButton,
  },
  data: () => ({
    dialog: false,
  }),
};
</script>