<template>
  <footer class="footer">
    <div class="footer__body">
      <div class="footer__rights">
        <div>© {{ new Date().getFullYear() }} MedDrive</div>
      </div>
      <div class="footer__legal">
        <router-link to="/privacy-policy">Privacy Policy</router-link>
        <router-link to="/terms-of-service">Terms and Conditions</router-link>
      </div>
      <div class="footer__apps">
        <a href="/">
          <img src="@/assets/images/footer/google-play-app.png" alt="" />
        </a>
        <a href="/">
          <img src="@/assets/images/footer/app-store-app.png" alt="" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;

  background: $primary-color;

  position: relative;
  z-index: 2;

  &__body {
    width: 100%;
    max-width: $containerMaxWidth;
    margin: 0px auto;

    padding: 85px 25px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 940px) {
      flex-direction: column;
      padding: 60px 25px;
    }
  }

  &__rights {
    font-size: 16px;
    color: $white;
  }

  &__legal {
    display: flex;
    margin: 0 30px;

    @media screen and (max-width: 940px) {
      margin: 30px 0;
    }

    @media screen and (max-width: 425px) {
      flex-direction: column;
    }

    a {
      display: block;

      font-size: 18px;
      color: $white;
      text-align: center;
      line-height: 140%;

      &:not(:last-child) {
        margin-right: 50px;

        @media screen and (max-width: 425px) {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }
  }

  &__apps {
    display: flex;

    height: 35px;

    a {
      height: 100%;
      display: flex;
      flex: 1 0 auto;

      &:not(:last-child) {
        margin-right: 30px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
